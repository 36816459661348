<template>
  <div>
    <a-spin :spinning="loading">
      <a-descriptions
        :column="3"
        class="iotplt-descriptions iotplt-descriptions-max-10"
      >
        <a-descriptions-item label="账单批次">
          {{ agentSubscribeOrder.no }}
        </a-descriptions-item>

        <a-descriptions-item label="订购周期数">
          {{ agentSubscribeOrder.cycles | formatBigNumber }}
        </a-descriptions-item>

        <a-descriptions-item label="操作人">
          {{ agentSubscribeOrder.operator_name }}
        </a-descriptions-item>

        <a-descriptions-item label="账单时间">
          {{ agentSubscribeOrder.created_at }}
        </a-descriptions-item>

        <a-descriptions-item label="卡号数量">
          {{ agentSubscribeOrder.cards_count | formatBigNumber }}
        </a-descriptions-item>

        <a-descriptions-item label="账单金额(元)">
          {{ agentSubscribeOrder.total_fee | formatCurrency }}
        </a-descriptions-item>
      </a-descriptions>
    </a-spin>

    <search-sim-card-subscribe-order @submit="submitSearch" />

    <div class="iotplt-table-operation-buttons">
      <a-button type="primary" @click="exportData">导出</a-button>
    </div>

    <a-table
      size="middle"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loadingSimCardSubscribeOrders"
      :pagination="false"
      @change="sortChange"
      row-key="id"
    >
      <span slot="product_price_title">
        <a-tooltip>
          <template slot="title">
            这里显示的是套餐周期单价，精确到两位小数
          </template>
          套餐价格(元)
          <a-icon type="question-circle" />
        </a-tooltip>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchSimCardSubscribeOrders"
    />
  </div>
</template>

<script>
import { findAgentSubscribeOrder, findSimCardSubscribeOrders, exportSimCardSubscribeOrders } from '@/api/agent_subscribe_order'
import SearchSimCardSubscribeOrder from '@/views/agent_monthly_bills/subscribe/show/Search'
import Pagination from '@/components/Pagination'
import { formatCurrency } from '@/utils/filter'
import { exportExcel } from '@/api/excel'

export default {
  name: 'ShowAgentSubscribeOrder',
  components: {
    Pagination,
    SearchSimCardSubscribeOrder
  },
  data() {
    return {
      agentSubscribeOrder: {},
      loading: true,
      loadingSimCardSubscribeOrders: true,
      pagination: {
        total_count: 0
      },
      query: {},
      sort: {
        sort_field: '',
        sort_order: ''
      },
      data: []
    }
  },
  computed: {
    agentType() {
      return this.$store.getters.userAgentType
    },

    columns() {
      var columnArr = [
        {
          title: 'ICCID',
          dataIndex: 'iccid',
          fixed: 'left',
          sorter: true,
          sortOrder: this.sort.sort_field === 'iccid' ? this.sort.sort_order : false,
          scopedSlots: { customRender: 'iccid' }
        },
        {
          title: '手机号码',
          dataIndex: 'phone_number'
        },
        {
          title: '客户套餐',
          width: 220,
          dataIndex: 'agents_product_name'
        },
        {
          slots: { title: 'product_price_title' },
          dataIndex: 'product_price',
          customRender: formatCurrency
        },
        {
          title: '订购周期数',
          dataIndex: 'cycles'
        },
        {
          title: '订购金额(元)',
          dataIndex: 'total_fee',
          customRender: formatCurrency
        }
      ]

      return columnArr
    },

    agentSubscribeOrderId() {
      return parseInt(this.$route.params.subscribe_order_id)
    }
  },
  created() {
    this.fetchData()
    this.fetchSimCardSubscribeOrders()
  },
  methods: {
    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchSimCardSubscribeOrders()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchSimCardSubscribeOrders()
    },

    fetchData() {
      this.loading = true
      findAgentSubscribeOrder(this.agentSubscribeOrderId).then((res) => {
        if (res.code === 0) {
          this.agentSubscribeOrder = res.data

          this.$store.dispatch('ConcatDynamicBreadCrumbs', [
            { redirect: `/agent_consumption_bills`, meta: { title: '消费账单' }},
            { redirect: ``, meta: { title: this.agentSubscribeOrder.no }}
          ]
          ).then(() => {})
        }
        this.loading = false
      })
    },

    fetchSimCardSubscribeOrders() {
      this.loadingSimCardSubscribeOrders = true
      findSimCardSubscribeOrders(this.agentSubscribeOrderId, Object.assign({},
        this.query, this.sort)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loadingSimCardSubscribeOrders = false
      })
    },

    exportData() {
      exportSimCardSubscribeOrders(this.agentSubscribeOrderId, this.query).then((res) => {
        if (res.code === 0 && res.data.is_direct_download) {
          exportExcel(res.data.id, res.data.file_name)
        }
      })
    }
  }
}
</script>

